.timelineComponent {
    padding: 10px 40px 10px 40px;
    width: 100%;
}

.locationPreview {
    position: relative;
    border: 2px solid $gray2;
    width: 100%;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    .locationPreview__CollapsibleInner {
        border: none;
    }
}
.timezoneTimeline {
    display: block;
    text-align: end;
    color: $gray3;
    margin: 5px;
    a {
        font-weight: bold;
        display: block;
    }
}
.locationPreview__loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    transform: scale(1.2);
}
.locationPreview__nameSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.locationPreview__locationName {
    font-size: 30px;
}
.locationPreview__locationAddress {
    font-size: 22px;
    opacity: 0.9;
}
.locationPreview__displayDate {
    padding: 15px 5px 10px 5px;
    display: flex;
    justify-content: end;
    align-items: end;
}
.unClicked {
    background-color: $gray1;
    padding: 10px;
    color: darkgray;
    border: none;
}
.unClicked:hover {
    background-color: $gray2;
    padding: 10px;
    color: rgb(156, 156, 156);
    border: none;
}
.button-left-timeline {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.button-right-timeline {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.onClicked {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
}
.onClicked:hover {
    background-color: #228b3a;
    color: white;
    padding: 10px;
    border: none;
}
.locationPreview__rowsWrapper {
    padding: 10px 0;
}
.locationPreview__row {
    position: relative;
    padding: 5px 10px;
}

.locationPreview__dateText {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.scheduleWizard {
    padding-top: 10px;
    width: 100%;
}
.scheduleWizard__createSchedule {
    display: flex;
    justify-content: center;
}
.scheduleWizard__newSchedule {
    padding-top: 15px;
    border-top: 2px solid rgba(4, 0, 252, 0.575);
}
.datePicker--portal {
    width: 215px;
    font-size: 18px;
    text-align: center;
}
/*
    COLLAPSIBLE
*/
.collapseHeader {
    background-color: $lightBlue0;
    padding: 10px;
    color: white;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.collapseHeader__icon {
    margin-right: 10px;
    transform: rotate(180deg);
    transition: 0.4s ease-in-out transform;
    $svgSize: 30px;
    display: flex;
    align-items: center;
    width: $svgSize;
    height: $svgSize;
    svg {
        height: 100%;
        width: 100%;
    }
}
.is-open {
    .collapseHeader__icon {
        transform: rotate(0);
    }
}
//The content within the collaspable area
.Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightBlue0;
    border-top: 0;

    p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.scheduleEditor {
    padding: 10px 0;
    border-top: 2px solid $gray2;
    &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
    }
    &:first-child {
        border-top: none;
        padding-top: 0px;
    }
}
