.langButton {
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 46px;
    align-items: center;
    position: fixed;
    bottom: 74px;
    right: 20px;
    border: 1px solid white;
    border-radius: 4px;
    padding: 7px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 16px;
    right: 20px;

    .langSelect {
        cursor: pointer;
        font-size: 15px;
        position: fixed;
        color: whitesmoke;
        line-height: 2;
        right: 78px;
        bottom: 74px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid white;
        border-radius: 4px;
        padding: 10px;
    }
    .langSelect :disabled {
        color: rgb(238, 238, 238);
        font-weight: bold;
    }
    .language {
        width: 30px;
        height: 30px;
    }
}
.connectionButton {
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 46px;
    align-items: center;
    position: fixed;
    bottom: 74px;
    right: 20px;
    border: 1px solid white;
    border-radius: 4px;
    padding: 7px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);

    .check-connection {
        position: absolute;
        top: 0;
        right: 57px;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 4px;
        border: 1px solid white;
        text-align: center;
        color: whitesmoke;
        fill: rgba(214, 208, 208, 0.897);
        .cmeter--sleep {
            .cmeter__iconWrapper {
                opacity: 0.5;
                fill: whitesmoke;
            }
        }
        .cmeter__iconWrapper {
            height: 50px;
            width: 50px;
            opacity: 0.7;
            border: 1px solid white;
        }
        .cmeter__dots {
            width: 40px;
            margin: 0 2px;
        }
    }

    .connectionIcon {
        width: 30px;
        height: 30px;
    }
}

.connectionButton {
    top: 16px;
    right: 20px;
}
.login-component {
    background-image: url("../components/login/img/login-vertical.png");
    background-size: cover;
    background-position: left bottom;
    height: 100%;
    min-height: 100vh;

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
    }
    /* Internet Explorer 10-11 */
    :-ms-input-placeholder {
        color: white;
    }
    /* Microsoft Edge */
    ::-ms-input-placeholder {
        color: white;
    }

    .login-wrapper,
    .location-wrapper {
        margin: auto;
        width: 400px;
    }

    .login-img-wrapper {
        margin: 15% auto;

        img {
            width: 35vh;
        }
    }
    .error-login {
        background-color: rgba(27, 25, 25, 0.308);
        padding: 8px;
        padding-bottom: 0px;
        border-radius: 4px;
        color: white;
        text-align: center;
    }

    .header {
        width: 100%;
        font-family: "Noto Sans JP", sans-serif;
        text-align: center;
        font-size: 80px;
        font-weight: bold;
        color: white;
        padding-bottom: 50px;
    }

    .login-control {
        margin: 8px auto;
        width: 400px;
        color: white;
        margin-top: 15px;
        .error-msg {
            position: absolute;
            color: red;
            font-size: 13px;
            font-weight: bold;
            margin-left: 5px;
        }
    }

    input {
        width: 100%;
        padding-left: 32px;
        color: white;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid white;
        outline: none;
    }
    .input-on-error {
        border-bottom: 2px solid red;
    }
    .e-mail-icon,
    .padlock-icon {
        position: relative;
        left: 8px;
        top: 22px;
        margin: 0;
        width: 14px;
        height: 15px;
    }
    .padlock-icon-error,
    .e-mail-icon-error {
        position: relative;
        left: 8px;
        top: 22px;
        margin: 0;
        width: 14px;
        height: 15px;
        color: red;
    }
    .e-mail-icon {
        top: 24px;
    }

    .logout-btn,
    .login-btn {
        color: white !important;
        margin-top: 25px;
        padding: 8px;
        background-color: $pink;
        border: 1px solid white;
    }
}

/* For general iPad layouts */
@media only screen and (device-width: 768px) {
    .login-component {
        .padlock-icon {
            top: 24px;
        }
        .e-mail-icon {
            top: 26px;
        }
    }
}

@media only screen and (max-width: 766px) {
    .langButton,
    .connectionButton {
        height: 36px;
        width: 36px;
        bottom: 60px;
        right: 15px;
        padding: 5px;
        bottom: 15px;

        .check-connection {
            right: 32px;
        }

        .langSelect {
            font-size: 15px;
            line-height: 1.5;
            right: 59px;
            bottom: 16px;
            padding: 6px;
        }
        .language,
        .connectionIcon {
            width: 24px;
            height: 24px;
        }
        .connectionButton {
            top: 15px;
        }
    }
    .login-component {
        .header {
            font-size: 60px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .langButton,
    .connectionButton {
        height: 26px;
        width: 26px;
        padding: 3px;
        bottom: 10px;
        right: 10px;

        .langSelect {
            font-size: 14px;
            line-height: 1.2;
            right: 42px;
            bottom: 10px;
            padding: 4px;
        }
        .language,
        .connectionIcon {
            width: 18px;
            height: 18px;
        }
    }
    .connectionButton {
        top: 10px;
        right: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .login-component {
        .header {
            font-size: 40px;
        }
        .login-control {
            width: 300px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .login-component {
        .header {
            font-size: 30px;
        }

        .login-control {
            width: 200px;
        }
    }
}

@media only screen and (min-device-width: 560px) and (max-device-width: 768px) and (orientation: landscape) {
    .login-component {
        .login-img-wrapper {
            margin: 10% auto;
            img {
                width: 45vw;
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .login-component {
        .login-img-wrapper {
            margin: 10% auto;
            img {
                width: 35vw;
            }
        }
    }
}
