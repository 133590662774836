.workflow-step {
    margin: 10px 0 15px 15px;
    text-align: center;
    float: left;
    color: #52284e;
    width: 60px;
    position: relative;

    div {
        display: inline-block;
        border: 3px solid #52284e;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        font-weight: bold;
        line-height: 28px;
    }

    &.completed {
        color: #80982b;
        div {
            border-color: #80982b;
        }
        & + .completed::before {
            content: "";
            position: absolute;
            top: 16px;
            left: -28px;
            width: 42px;
            height: 3px;
            background: #80982b;
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    p {
        font-size: 12px;
    }
}
