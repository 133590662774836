.patient-in-queue {
    span {
        color: $gray3;
        font-size: 14px;
    }
    tr {
        flex-grow: 1;
        padding: 0 10px;
        border-left: 2px solid $gray1;
        margin-left: 10px;
    }
    .div-content {
        border-right: 2px solid $gray1;
        font-weight: normal;
        svg {
            color: $gray4;
        }
    }
    .actionsButton {
        .div-content {
            border-right: none;
            button {
                background-color: white;
                border: 1px solid $gray2;
                color: $gray4;
                font-weight: bold;
            }
        }
    }
}
.patient-in-queue.row {
    border-bottom: 1px solid $gray1;
    padding: 0;
    margin: 0 8px;
}

.patient-in-queue:hover {
    background-color: $blue1;
}

.patients-in-queue {
    tr {
        padding: 0px 16px;
    }
    th {
        padding: 12px 4px;
    }
    thead {
        margin: 0 -6px;

        background-color: $red1;
        .arrow {
            border: solid $gray4;
            border-width: 0 1px 1px 0;
            padding: 2px;
            margin-bottom: 2px;
        }
        tr {
            color: $gray4;
            font-size: 14px;
            th {
                font-weight: normal;
                padding: 0 16px;
                border: none;
                div {
                    button {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .patients-in-queue {
        .lg-dispaly {
            display: none;
        }
    }
    .patient-in-queue {
        .actionsButton {
            .div-content {
                border-right: 2px solid $gray1;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .patient-in-queue {
        .sm-display {
            display: none;
        }
    }

    .patients-in-queue {
        padding-left: 26px;
    }
}
