.react-bootstrap-table {
    cursor: pointer;
    thead {
        tr {
            th {
                padding: 5px 16px 0 16px;
                font-weight: 400;
                font-size: 14px;
                label {
                    margin: 0;
                    select {
                        display: block;
                        width: 20%;
                        height: 18px;
                        text-indent: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        cursor: pointer;
                        padding: 0;
                        margin: 0 0 0 10px;
                        border: none;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.5;
                        background-color: blue;
                        color: white;
                    }
                    .placeholder-selected {
                        background-color: white;
                        color: black;
                    }
                }
            }
            .sortable {
                padding-bottom: 5px;
            }
        }
    }
    tbody {
        tr {
            border-bottom: 2px solid $gray1;
            padding: 0;
            margin: 0 8px;

            td {
                .spacer {
                    border-right: 2px solid $gray1;

                    .scheduled {
                        color: $neonBlue1 !important;
                    }
                    span {
                        color: $gray3;
                        font-size: 14px;
                    }
                }
            }
            td:last-child {
                .spacer {
                    border-right: none;
                }
            }
        }

        tr:hover {
            background-color: $blue1;
        }
        .reset-expansion-style:hover {
            cursor: auto;
            background-color: white;
        }
        .nav-tabs {
            border-bottom: 2px solid $gray1;
            .nav-link {
                color: black;
            }
        }
    }
}
