.appointment-container {
    margin-right: 2px;
    margin-left: 2px;
    width: 100%;

    .appointment-time-details {
        display: flex;

        .name {
            flex-grow: 1;
        }
    }
}
