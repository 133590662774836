$border: 1px solid $black;
$line: 2px solid $black;

.button-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.column-name {
    border-bottom: $border;

    & > input {
        margin: 0.5rem 1rem;
    }
}

.draggable-column-name {
    cursor: move;
    display: flex;
    align-items: center;
    border-bottom: $border;

    & > .drag-icon {
        width: 1rem;
        height: 0.8rem;
        border-bottom: $line;
        margin: 0.5rem 1rem;

        &::before {
            content: " ";
            display: block;
            width: 1rem;
            height: 0.35rem;
            border-top: $line;
        }

        &::after {
            content: " ";
            display: block;
            width: 1rem;
            height: 0.3rem;
            border-top: $line;
        }
    }
}
