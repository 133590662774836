header {
    span {
        font-weight: 600;
        margin-top: 10px;
        text-align: center;
        color: darkgray;
        font-size: 24px;
        flex-grow: 1;
    }
    .active-queue-span-1 {
        text-align: left;
        padding: 0 28px;
    }
    .active-queue-span-3 {
        text-align: right;
        margin-top: 17px;
        font-size: 18px;
        padding: 0 28px;
    }
    hr {
        margin-bottom: 0;
        margin-left: -16px;
        display: block;
        width: calc(100% + 32px);
        background-color: $gray4;
    }
}

@media only screen and (max-width: 768px) {
    header {
        span {
            padding: 0 20px;
        }
        .active-queue-span-1,
        .active-queue-span-3 {
            text-align: center;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}
