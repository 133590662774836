@import "~bootstrap/scss/bootstrap";
@import "./colors";
@import "./login_component";
@import "./nav.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./patients_queue.scss";
@import "./appointment-event.scss";
@import "./table.scss";
@import "./table-settings.scss";
@import "./expandRows.scss";
@import "./timelineComponent";

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}
