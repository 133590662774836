.nav-container {
    background-color: $blue4;
    height: 100%;
    min-height: calc(100vh - 56px);
    width: 56px;
    float: left;
    .nav-content {
        div {
            padding: 4px;
            a {
                svg {
                    color: white;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                    border-radius: 4px;
                }
                svg:hover {
                    background-color: $blue3;
                }
            }
        }
        .nav-home {
            background-color: $blue5;
            svg {
                color: $neonBlue1;
            }
        }
        .nav-settings {
            border-top: 1px solid white;
            margin: 0 4px;
            margin-top: calc(100vh - 450px);
            padding: 0px;
        }
        .dropdown-menu {
            background-color: $blue3;
            transform: translate3d(52px, 0px, 0px) !important;
            z-index: 2000 !important;
        }
        .dropdown-toggle {
            padding: 4px;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropright {
            a {
                color: white;
                font-size: 14px;
            }
            .dropdown-divider {
                margin: 6px 0;
            }
        }
    }
}

.popover {
    background-color: $gray4;
    color: #fff;
    margin-left: 0px;
    margin-top: -20px;
    .arrow {
        display: none;
    }
}

.hidden-menu {
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 56px;
    color: $gray3;
    font-weight: 500;
    height: 100%;
    min-height: calc(100vh - 56px);
    width: 10px;
    transition: width 1s;
    background-color: $blue0;
    border-right: 1px solid $gray2;
    text-align: center;
    padding-top: 60px;
    div {
        font-size: 0px;
        transition: width, font-size 1s;
        p {
            background-color: white;
            position: absolute;
            top: 16px;
            width: 20px;
            transition: width 1s;
            height: 24px;
            border-top: 1px solid $gray2;
            border-bottom: 1px solid $gray2;
            border-right: 1px solid $gray2;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            .arrow {
                margin-top: 7px;
                margin-left: 0px;
                position: absolute;
                right: 4px;
            }
        }
        a {
            color: $blue0;
            transition: color 0.5s ease-in;
        }
    }
    .arrow {
        border: solid $gray3;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-bottom: 1px;
    }
    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
}

.hidden-menu.clicked {
    width: 190px;
    div {
        font-size: 16px;
        p {
            width: 200px;
        }
        a {
            color: $gray3;
        }
    }
}

@media only screen and (max-height: 500px) {
    .nav-container {
        .nav-content {
            .nav-settings {
                margin-top: 0px;
                border-top: none;
            }
        }
    }
}
